import showMessage from 'show-message';

export default function() {
  $('.order__form').validate({
    submitHandler: function(form, event) {
      event.preventDefault();
      const data = new FormData(form);
      $.ajax({
        url: '/sendmail.php',
        type: 'POST',
        data: data,
        processData: false,
        contentType: false
      }).done(function(){
        form.reset();
        $(form).append('<div class="success">Thank you! We\'ll get back to you shortly.</div>');
      }).fail(function(){
        showMessage('message--error');
      });
    }
  });
  $('.order__btn-send').on('click', function(){
    $(this).closest('form').find('.success').remove();
  });
}
