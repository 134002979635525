export default function() {
  const rocket1 = $('.space__rocket.rocket-1');
  const rocket2 = $('.space__rocket.rocket-2');

  let screenWidth = 0;
  let screenWidthMultipler = 0;

  let step = 0;

  updateScreenWidthParams();
  updateRocketPosition();

  setTimeout(function(){
    rocket1.addClass('inited');
    rocket2.addClass('inited');
  }, 100);

  $(window).on('resize', updateScreenWidthParams);

  function updateScreenWidthParams() {
    screenWidth = document.documentElement.clientWidth;
    screenWidthMultipler = 2 - screenWidth/3200;
  }

  function updateRocketPosition() {
    const newStep = $(window).scrollTop();

    if(newStep > step || step == 0) {
      const innerRect = document.querySelector('.space__inner').getBoundingClientRect();
      const rectOffset = innerRect.height/2;
      const top = innerRect.top - rectOffset*1.5;
      const bottom = innerRect.bottom - rectOffset*0.3;

      let offset = 0;

      if(top < 0 && bottom > 0) {
        offset = -top / (-top + bottom) * 100;
      } else if (top > 0) {
        offset = 0;
      } else if (bottom < 0) {
        offset = 100;
      }

      rocket1.css({
        transform: `translate3d(${screenWidth*(offset * screenWidthMultipler)/100}px, 0, 0)`
      });

      rocket2.css({
        transform: `translate3d(${screenWidth*((-100+offset) * screenWidthMultipler)/100}px, 0, 0)`
      });

      step = newStep;
    }

    requestAnimationFrame(updateRocketPosition);
  }
}
