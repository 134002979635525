export default function() {
  let isMenuOpen = false;
  const $document = $(document);
  const $container = $('.lang-toggler');

  const onMenuOpen = function onMenuOpen() {
    $container.addClass('lang-toggler--opened');
    $document.on('click', '.lang-toggler__link', onDocumentClick);
  };

  const onMenuClose = function onMenuClose() {
    $container.removeClass('lang-toggler--opened');
    $document.off('click', '.lang-toggler__link', onDocumentClick);
  };

  const onDocumentClick = function onDocumentClick(event) {
    if ($.contains($container[0], event.target)) {
      return;
    }
    onMenuClose();
  };

  $document.on('click', '.lang-toggler__btn', function () {
    isMenuOpen = !isMenuOpen;
    isMenuOpen ? onMenuOpen() : onMenuClose();
  });
}