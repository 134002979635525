import langToggler from 'lang-toggler';
import questions from 'questions';
import emailSendForm from 'email-send-form';
import orderForm from 'order-form';
import rocket from 'rocket';

$(function(){

  $('.menu-btn').on('click', function(){
    toggleMenu();
  });

  $('.menu__bg, .menu__item').on('click', function() {
    toggleMenu(false);
  });


  $('form').on('input', '.formgroup__input.error', function(e){
    $(e.target).removeClass('error').next('.error').remove();
  });

  emailSendForm();
  orderForm();
  questions();
  langToggler();
  rocket();

  let oldHash = '';
  window.addEventListener("popstate", stateChange);
  stateChange();

  const anchors = $('a[href^="#"]');
  anchors.on('click', function(e){
    e.preventDefault();
    const href = $(this).attr('href');
    window.history.pushState({}, '', href);
    stateChange();
  })

  function stateChange(){
    const hash = window.location.hash.replace('#','').toLowerCase();

    if(hash !== oldHash){
      hashChange(hash);
    }
    oldHash = hash;
  }

  function hashChange(hash){
    if(hash == 'order'){
      toggleMenu(false);
      toggleOrder(true);
    }else{
      toggleOrder(false);
      scrollTo(hash);
    }
  }

  function toggleMenu(show){
    if(typeof show == 'undefined'){
      show = !$('.menu').hasClass('menu--active');
    }
    $('.menu').toggleClass('menu--active', show);
    $('.menu-btn').toggleClass('menu-btn--close', show);
    $('.header__burger').toggleClass('fixed', show);
    if(show){
      toggleOrder(false);
    }
  }


  function toggleOrder(show){
    $('.order').toggle(show);
    $('body').toggleClass('no-scroll', show);
    if(!show){
      oldHash = '';
      window.history.pushState({}, '', '/');
    }
  }

  function scrollTo(name){
    const element = $('[id="'+name+'"]');
    if(element.length == 0){
      return;
    }
    $('html, body').animate({
        scrollTop: element.offset().top
    }, 500);
  }
});
